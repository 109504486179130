import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {Link} from 'react-router-dom';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import TaskAltIcon from '@mui/icons-material/TaskAlt';

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import Button from '@mui/material/Button';

import { AppBar, Toolbar } from '@mui/material';

import { InputLabel, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import axios from "axios";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import WpComponent from './WpComponent';

// import ChatFooter from './ChatFooter';

function Footer() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setMsg("");
        // setMsgcolor("");
        setColor("");

        setName("");
        setMobile("");
        setEmail("");
        setCity("");
        setInterest("");

        setIserrname(false);
        setIserrmobile(false);
        setIserremail(false);
        setIserrinterest(false);
        setIserrdetails(false);

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const buttonStyle = {
        color: 'white',       // Text color
        backgroundColor: 'red', // Background color
        '&:hover': {
            backgroundColor: 'darkred', // Hover background color
        },
        fontSize: '1.1rem',
    };

    const whatsappStyle = {
        color: 'white',       // Text color
        backgroundColor: 'green', // Background color
        '&:hover': {
            backgroundColor: 'darkred', // Hover background color
        },
        fontSize: '2.8rem',
        borderRadius: '48%',
        cursor: 'pointer',
    };

    const errtxt={
        fontSize: 'small',
        color: 'red'
    };

    let [name,setName]=useState("");
    let [mobile,setMobile]=useState("");
    let [email,setEmail]=useState("");
    let [city,setCity]=useState("");
    let [interest,setInterest]=useState("");
    let [details,setDetails]=useState("");

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const [iserrname,setIserrname]=useState(false);
    const [iserrmobile,setIserrmobile]=useState(false);
    const [iserremail,setIserremail]=useState(false);
    const [iserrinterest,setIserrinterest]=useState(false);
    const [iserrdetails,setIserrdetails]=useState(false);

    let [msg,setMsg]=useState("");
    // let [msgcolor,setMsgcolor]=useState("");
    let [statuscode,setStatuscode]=useState(false);

    let [isbtn,setIsbtn]=useState(false);

    let [color,setColor]=useState("");

    async function handleQuote(e) {
        setMsg("");
        // setMsgcolor("");
        setColor("");

        var flag=1;

        if (name.length==0 && name.trim()=="") {
            flag=0;
            setIserrname(true);
        } else {
            setIserrname(false);
        }

        if ((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{9})$/.test(mobile) || !isNaN(mobile)) && mobile.trim()!="") {
            setIserrmobile(false);
        } else {
            flag=0;
            setIserrmobile(true);
        }
        
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            flag=0;
            setIserremail(true);
        } else {
            setIserremail(false);
        }

        if (interest.length==0) {
            flag=0;
            setIserrinterest(true);
        } else {
            setIserrinterest(false);
        }

        if (details=="") {
            flag=0;
            setIserrdetails(true);
            // alert(details)
        } else {
            setIserrdetails(false);
        }

        if (flag==1) {
            var fd=new FormData();
            fd.append('name',name);
            fd.append('email',email);
            fd.append('mobile',mobile);
            fd.append('interest',interest);
            fd.append('details',details);

            // e.preventDefault();

            // var resp=await axios.post("https://www.cheapwebguru.com/cwguru/api/quote", {name, email, mobile, city, interest});
            var resp=await axios.post("https://www.cheapwebguru.com/blog/api/sendquote", fd);
            var data=resp.data;

            setMsg(data.msg);
            // setMsgcolor(data.msgcolor);
            // setColor(data.color);

            if (data.statuscode==200) {
                setName("");
                setEmail("");
                setMobile("");
                setCity("");
                setInterest("");
                setDetails("");
            } else {
                setName("");
                setEmail("");
                setMobile("");
                setCity("");
                setInterest("");
                setDetails("");
            }

        }
    };

    return(
        <>
           
            <Grid className="ftBg" pt={5} pb={3}>
                <Container>
                    <Grid container>
                        <Grid item sm={3} className="ft_link" >
                            <Typography variant="h6" pb={1}>Useful Links</Typography>
                            <Link  to="/"><i class="arrow right"></i> Home</Link><br />
                            <Link  to="/about"><i class="arrow right"></i> About</Link><br />
                            <Link  to="/portfolio"><i class="arrow right"></i> Portfolio</Link><br />
                            <Link  to="/careers"><i class="arrow right"></i> Careers</Link><br />
                            <Link  to="/terms-of-services"><i class="arrow right"></i> Terms of Services</Link><br />
                            <Link  to="/privacy-policy"><i class="arrow right"></i> Privacy Policy</Link><br />

                          
                        </Grid>
                        <Grid item sm={3} className="ft_link">
                        <Typography variant="h6" pb={1}>Our Services</Typography>
                       

                            <Link  to="/services/brand-designing"><i class="arrow right"></i> Design Branding</Link><br />
                            <Link  to="/services/web-development"><i class="arrow right"></i> Web Solutions</Link><br />
                            <Link  to="/services/app-developmen"><i class="arrow right"></i>  App Development</Link><br />
                            <Link  to="/services/digital-marketing"><i class="arrow right"></i>  Digital Ad Marketing</Link><br />
                            <Link  to="/services/webhosting"><i class="arrow right"></i> Web Hosting & Server</Link><br />
                                
                        </Grid>
                        <Grid item sm={3}    >
                            <Typography variant="h6" pb={1}>Contact Us</Typography>
                            <Typography variant="body2" fontWeight="bold">M/S CHEAP WEB GURU SOLUTIONS</Typography>
                            <Typography variant="body2">8/4 Abhoy Ghosh Road, Mitra Para, Naihati, 743165, WB (INDIA)</Typography>
                            {/* <Typography variant="body2">NEAR PRASAD NAGAR SCHOOL</Typography> */}
                            {/* <Typography variant="body2">NAIHATI, 743165</Typography> */}
                            <Typography variant="body2" pb={2}>WB (INDIA)</Typography>

                            <Typography variant="body2" fontWeight="bold">Phone:</Typography>
                            <Typography variant="body2" pb={2}>+91-8910834910 / +91-8013561965</Typography>

                            <Typography variant="body2" fontWeight="bold" pb={1}>Email:</Typography>
                            <Typography variant="body2" pb={2}>info@cheapwebguru.com</Typography>
                        </Grid>
                        <Grid item sm={3} className="ft_link"    >
                            <Typography variant="h6" pb={1}>About CHEAPWEBGURU</Typography>
                            <Typography variant="body2" pb={2}>One of the freelance web service in India. We provides cheap rated web design with development, web advertising, app deveopment and promotion around the world as per client recommandation.</Typography>

                            <Typography variant="body2" className='socialIcon'>
                            <Link to="https://www.linkedin.com/company/cheap-web-guru-solutions/" target="_blank"><Button variant="outline" startIcon={<LinkedInIcon />}></Button></Link>
                            <Link to="http://www.twitter.com/" target="_blank"><Button variant="outline" startIcon={<TwitterIcon />}></Button></Link>
                            <Link to="https://www.facebook.com/cheapwebguru" target="_blank"><Button variant="outline" startIcon={<FacebookIcon />}></Button></Link>
                            <Link to="http://www.instagram.com/cheapwebguru" target="_blank"><Button variant="outline" startIcon={<InstagramIcon />}></Button></Link>
                                
                            
                            </Typography>
                            
                        </Grid>

                    </Grid>
                </Container>
            </Grid>

            <Grid className="ftBg2" variant pb={6}>
                <Container>
                    <Grid container >
                        <Grid item sm={12} textAlign="center" py={1}  >
                        &#169; Copyright cheapwebguru.com. All Rights Reserved.<br />
                        Designed and developed by <Link to="https://www.cheapwebguru.com/" target="_blank">CHEAPWEBGURU</Link>.          
                        </Grid>
                </Grid>
                </Container>
            </Grid>

            {/* Footer with fixed button */}
            {/* <AppBar position="fixed" style={{ top: 'auto', bottom: 115, backgroundColor: 'transparent', boxShadow: 'none', alignItems: 'flex-end' }}>
                <Toolbar>
                    <WhatsAppIcon style={whatsappStyle} onClick={()=>{
                        window.open("https://api.whatsapp.com/send?phone=918013561965&text=Hello, I'm reaching out to see if you'd be interested in a query. Please let me know if you are available to discuss.")
                    }}/>
                </Toolbar>
            </AppBar> */}

            {/* <AppBar position="fixed" style={{ top: 'auto', bottom: 60, backgroundColor: 'transparent', boxShadow: 'none', alignItems: 'flex-end' }}>
                <Toolbar>
                    <WhatsAppIcon style={whatsappStyle} onClick={()=>{
                        window.open("https://api.whatsapp.com/send?phone=918910834910&text=Hello, I'm reaching out to see if you'd be interested in a query. Please let me know if you are available to discuss.")
                    }}/>
                </Toolbar>
            </AppBar> */}

            <AppBar position="fixed" style={{ top: 'auto', bottom: 60, backgroundColor: 'transparent', boxShadow: 'none', alignItems: 'flex-end' }}>
                <Toolbar>
                    <WpComponent />
                </Toolbar>
            </AppBar>

            <AppBar position="fixed" style={{ top: 'auto', bottom: 300, backgroundColor: 'transparent', boxShadow: 'none', alignItems: 'flex-end' }}>
                <Toolbar>
                    <Button style={buttonStyle} variant="contained" onClick={handleOpen}>
                        Get Quote
                    </Button>
                </Toolbar>
            </AppBar>

            {/* <AppBar position="fixed" style={{ top: 'auto', bottom: 250, right: 0, backgroundColor: 'transparent', boxShadow: 'none', alignItems: 'flex-end' }}>
                <Toolbar><Button variant="outline" startIcon={<SpeakerNotesIcon onClick={handleOpen} style={{color: 'red',width: '5rem',height: '10vh'}}/>}></Button></Toolbar>
            </AppBar> */}
            {/* Footer with fixed button */}

            {/* Chat Footer */}
            {/* <ChatFooter /> */}
            {/* Chat Footer */}


            <Dialog 
                open={open} 
                onClose={handleClose} 
                fullWidth
                maxWidth="sm" // Adjust the maximum width
                PaperProps={{
                    sx: {
                    width: isMobile ? '90%' : '50%', // Customize width for mobile and desktop
                    },
                }}
            >
                {/* <DialogTitle>Get Quote</DialogTitle> */}
                {/* <DialogTitle sx={{textAlign: "center", fontWeight: 700}}>Complete the form to receive a prompt callback from us!</DialogTitle> */}
                <DialogTitle sx={{textAlign: "center", fontWeight: 700}}>Get in Touch</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    <Grid>
                    {/* container spacing={1} */}
                        <Grid sx={{flexGrow: 1, textAlign: "center", fontWeight: 700}}>
                            {msg.length>0?
                            <Grid>
                                <Grid pt={0} pb={0} sx={{color: 'green'}}>
                                <Button variant="outline" startIcon={<TaskAltIcon style={{fontSize: "2rem"}} />}></Button>
                                </Grid>
                                <Grid pt={0} pb={0} sx={{color: 'green'}}>
                                    {msg}
                                </Grid>
                            </Grid>
                            :''}
                        </Grid>

                        <Grid item xs={12} mt={0}>
                            <TextField label="Your Full Name *" variant="standard" placeholder="Full Name" fullWidth onChange={(ev)=>{
                                setName(ev.target.value);
                                if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                    setIserrname(false);
                                }
                            }} value={name} />

                            {iserrname==true?<Typography className="text text-danger" style={errtxt}>name required</Typography>:''}
                        </Grid>
                        
                        <Grid container spacing={1} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Your Phone Number" inputProps={{ maxLength: 15 }} variant="standard" placeholder="Phone Number" fullWidth onChange={(ev)=>{
                                    setMobile(ev.target.value);
                                    if ((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{9})$/.test(ev.target.value)) || !isNaN(ev.target.value)) {
                                        setIserrmobile(false);
                                    } else {
                                        setIserrmobile(true);
                                    }
                                }} value={mobile} />

                                {iserrmobile?<Typography className="text text-danger" style={errtxt}>valid phone number required</Typography>:''}
                            </Grid>
                            
                            
                            <Grid item xs={12} sm={6}>
                                <TextField label="Your Email ID *" variant="standard" placeholder="Email ID" fullWidth onChange={(ev)=>{
                                    setEmail(ev.target.value);
                                    if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) || email.length==0) {
                                        setIserremail(false);
                                    }
                                }} value={email} />

                                {iserremail?<Typography className="text text-danger" style={errtxt}>valid email id required</Typography>:''}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                        <InputLabel id="select-label">Request for Services*</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={interest}
                            onChange={(event)=>{
                                setInterest(event.target.value);
                                if (event.target.value.length>0) {
                                    setIserrinterest(false);
                                }
                            }}
                            label="Select an option"
                            fullWidth
                            >
                            <MenuItem value="">
                                <em>Request for Services</em>
                            </MenuItem>
                            <MenuItem value="Web Design &amp; Development">Web Design &amp; Development</MenuItem>  
                            <MenuItem value="Mobile App Development">Mobile App Development</MenuItem>                          
                            <MenuItem value="Ecommerce Development">Ecommerce Development</MenuItem>
                            <MenuItem value="Digital Ads Marketing">Digital Ads Marketing</MenuItem>  
                        </Select>

                            {iserrinterest?<Typography className="text text-danger" style={errtxt}>select interest</Typography>:''}
                        </Grid>

                        <Grid item xs={12} mt={3}>
                            <TextField
                                label="Your Requirement Details *"
                                multiline
                                rows={2}
                                placeholder="Enter your requirement details"
                                variant="outlined"
                                fullWidth
                                onChange={(ev)=>{
                                    setDetails(ev.target.value);
                                    if (ev.target.value!="") {
                                        setIserremail(false);
                                    } else {
                                        setIserremail(true);
                                    }
                                }}
                                value={details}
                            />
                            {iserrdetails?<Typography className="text text-danger" style={errtxt}>required details</Typography>:''}
                        </Grid>

                        {/* <Grid item xs={12} mt={3}>
                        <InputLabel id="select-label">Your Country *</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={interest}
                            onChange={(event)=>{
                                setInterest(event.target.value);
                                if (event.target.value.length>0) {
                                    setIserrinterest(false);
                                }
                            }}
                            label="Select an option"
                            fullWidth
                            >
                            <MenuItem value="">
                                <em>Select Your Country</em>
                            </MenuItem>
                            <MenuItem value="India">India</MenuItem>                          
                        </Select>

                            {iserrinterest?<Typography className="text text-danger" style={errtxt}>select interest</Typography>:''}
                        </Grid>

                        <Grid item xs={12} mt={2}>
                            <TextField label="Your City *" variant="standard" placeholder="City" fullWidth onChange={(ev)=>{
                                setCity(ev.target.value);
                            }} value={city} style={{borderBottom: '1px solid #f7faff'}} />
                        </Grid> */}

                        

                        {/* <Grid item xs={12} mt={3}>
                        <InputLabel id="select-label">Select interest *</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={interest}
                            onChange={(event)=>{
                                setInterest(event.target.value);
                                if (event.target.value.length>0) {
                                    setIserrinterest(false);
                                }
                            }}
                            label="Select an option"
                            fullWidth
                            >
                            <MenuItem value="">
                                <em>Your Interest</em>
                            </MenuItem>
                            <MenuItem value="Shopping/Ecommerce Website">Shopping/Ecommerce Website</MenuItem>
                            <MenuItem value="Tour Travel/Event Website">Tour Travel/Event Website</MenuItem>
                            <MenuItem value="Educational/Academic Website">Educational/Academic Website</MenuItem>
                            <MenuItem value="Wedding Website">Wedding Website</MenuItem>
                            <MenuItem value="Kid-friendly Website">Kid-friendly Website</MenuItem>
                            <MenuItem value="Doctor Appointment Website">Doctor Appointment Website</MenuItem>
                            <MenuItem value="Booking Website">Booking Website</MenuItem>
                            <MenuItem value="Blog/Forum Website">Blog/Forum Website</MenuItem>
                            <MenuItem value="Non Profit/NGO Website">Non Profit/NGO Website</MenuItem>
                            <MenuItem value="Blog/Forum Website">Blog/Forum Website</MenuItem>
                            <MenuItem value="Billing/Stock Project">Billing/Stock Project</MenuItem>
                            <MenuItem value="Informatic Website">Informatic Website</MenuItem>
                            <MenuItem value="Other Website">Other Website</MenuItem>
                            <MenuItem value="Mobile Application">Mobile Application</MenuItem>
                            <MenuItem value="Google Advertising">Google Advertising</MenuItem>
                            <MenuItem value="Website Traffic Booster">Website Traffic Booster</MenuItem>
                            <MenuItem value="Logo/Banner Design">Logo / Banner Design</MenuItem>                            
                        </Select>

                            {iserrinterest?<Typography className="text text-danger" style={errtxt}>select interest</Typography>:''}
                        </Grid> */}

                        

                    </Grid>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleQuote} color="primary" autoFocus>
                    Request Quote
                </Button>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                </DialogActions>
                <DialogActions>
                <Typography sx={{alignItems: 'center', color: 'red', fontSize: 'small'}}>[* mendatory]</Typography>
                </DialogActions>
                
            </Dialog>

        </>
    );
}

export default Footer;